import * as React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Button, MenuItem, AppBar, Box, Toolbar, IconButton, Typography, Menu, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Menu as MenuIcon, LocationOn as LocationOnIcon, Favorite as FavoriteIcon, Folder as FolderIcon, Restore as RestoreIcon } from '@mui/icons-material';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

const pages = [{ name: '매치업 만들기', path: '/dataupload' }];

function ResponsiveFooter() {
  const [value, setValue] = React.useState('recents');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <BottomNavigation sx={{ width:'100%',display: { xs: 'flex', md: 'none' }, position: 'fixed', bottom: 0 }} value={value} onChange={handleChange}>
      <BottomNavigationAction
        label="홈"
        value="홈"
        component={Link}
        to="/"
        icon={<HomeRoundedIcon />}
      />
      <BottomNavigationAction
        label="만들어"
        value="만들어"
        component={Link}
        to="/dataupload"
        icon={<AddCircleRoundedIcon />}
      />
    </BottomNavigation>
  );
}

function TopAppBarMd(){
  return(
    <Box sx={{ borderTop: '1px #DDD solid', borderBottom: '1px #DDD solid' }}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={4} align="center">
          <Button variant="text" component={Link} to="/dataupload" sx={{ fontWeight:'bold', color:'black', fontSize: { xs: '10px', md: '16px' }, }}>
            Create a Matchup🐣
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 800,
              letterSpacing: '.3rem',
              color: '#FFFFFF',
              textDecoration: 'none',
            }}
          >
            180Duck.com
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <Link to={page.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                      {page.name}
                    </Link>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: '#FFFFFF',
              textDecoration: 'none',
            }}
          >
            180Duck.com
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                component={Link}
                to={page.path}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block', fontSize:18, fontWeight:'bold' }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export { ResponsiveAppBar, ResponsiveFooter, TopAppBarMd };