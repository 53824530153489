import React from "react";

import MatchDetailContainer from "../containers/MatchDetailContainer"

export default function MatchDetailView() {
  return (
    <React.Fragment>
      <MatchDetailContainer />
    </React.Fragment>
  );
}
