//MatchDetailContainer.js

import React, { useEffect, useState, Fragment } from 'react';
import { Container, CircularProgress, Typography } from '@mui/material';
import MatchDetail from '../components/MatchDetailBlock';
import { firestore } from '../configs/firebaseConfig';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';

export default function MatchDetailContainer() {
  const { matchId } = useParams();
  const [matchData, setMatchData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchMatchData = async () => {
      try {
        const docRef = doc(firestore, "matchup", matchId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setMatchData(docSnap.data());
        } else {
          console.log("No such document!");
          setError("Match not found");
        }
      } catch (err) {
        console.error('Error fetching match data: ', err);
        setError('Failed to load match data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchMatchData();
  }, [matchId]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography>{error}</Typography>;
  }

  return (
    <Fragment>
      <Container maxWidth="md">
        {matchData && <MatchDetail matchData={matchData} />}
      </Container>
    </Fragment>
  );
}
