import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        body: {
          "::-webkit-scrollbar": {
            display: "none",
          },
        },
      }),
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1740,
    },
  },
  palette: {
    primary: {
      main: "#00a2f9",
    },
    secondary: {
      main: "#e7f5ff",
      dark: "#20DA75",
    },
    text: {
      primary: "#000000",
      secondary: "#000000",
    },
    background: {
      default: "#fff",
    },
    grey: {
      50: "#F8F8F8",
      100: "#EAECEE",
      200: "#DCDFE1",
      300: "#BBBDBF",
      400: "#9DA0A1",
      500: "#606264",
      600: "#404244",
      700: "#212121",
      800: "#151515",
      900: "#0C0C0C",
    },
    neutral: {
      main: "#0C0C0C",
      contrastText: "#FDFDFD",
    },
  },
  typography: {
    fontFamily: ["SpoqaHanSansNeo"].join(","),

    h1: {
      fontSize: 54,
      fontWeight: 700,
      letterSpacing: "-0.03em",
    },

    h2: {
      fontSize: 34,
      fontWeight: 700,
      letterSpacing: "-0.03em",
    },
    h3: {
      fontSize: 30,
      fontWeight: 700,
      letterSpacing: "-0.03em",
    },
    h4: {
      fontSize: 28,
      fontWeight: 700,
      letterSpacing: "-0.03em",
    },
    h5: {
      fontSize: 24,
      fontWeight: 700,
      letterSpacing: "-0.03em",
    },
    h6: {
      fontSize: 18,
      fontWeight: 700,
      letterSpacing: "-0.03em",
    },

    subtitle1: {
      fontSize: 20,
      fontWeight: 400,
      letterSpacing: "-0.03em",
    },
    subtitle2: {
      fontSize: 18,
      fontWeight: 400,
      letterSpacing: "-0.03em",
    },
    body1: {
      fontSize: 16,
      fontWeight: 650,
      letterSpacing: "-0.03em",
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: "-0.03em",
    },
    body3: {
      fontSize: 13,
      fontWeight: 400,
      letterSpacing: "-0.03em",
    },
    caption1: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: "-0.03em",
    },
    caption2: {
      fontSize: 10,
      fontWeight: 400,
      letterSpacing: "-0.03em",
    },
  },
});

export default theme;