import React from "react";

import DatauploadContainer from "../containers/DatauploadContainer"

export default function DatauploadView() {
  return (
    <React.Fragment>
      <DatauploadContainer />
    </React.Fragment>
  );
}
