//DatauploadContainer.js

import React, { Fragment } from "react";

import DatauploadForm from "../components/DatauploadBlock"

export default function DatauploadContainer() {
  return (
    <Fragment>
      <DatauploadForm/>
    </Fragment>
  );
}