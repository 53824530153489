//DatauploadBlock.js

import React, { useState, useCallback } from 'react';
import { Button, TextField, Grid, Typography, Container, Card, CardContent, CardMedia, Snackbar, Alert } from '@mui/material';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firestore, storage } from '../configs/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

export default function DataUploadForm() {
  const [mainTitle, setMainTitle] = useState('');
  const [typeATitle, setTypeATitle] = useState('');
  const [typeBTitle, setTypeBTitle] = useState('');
  const [typeAThumbnail, setTypeAThumbnail] = useState(null);
  const [typeBThumbnail, setTypeBThumbnail] = useState(null);
  const [typeAPreview, setTypeAPreview] = useState('');
  const [typeBPreview, setTypeBPreview] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('info');
  const [step, setStep] = useState(1);

  const handleFileChange = (type, event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileURL = URL.createObjectURL(file);
    if (type === 'typeA') {
      setTypeAThumbnail(file);
      setTypeAPreview(fileURL);
    } else if (type === 'typeB') {
      setTypeBThumbnail(file);
      setTypeBPreview(fileURL);
    }
  };

  const uploadFileToStorage = async (file, folder) => {
    if (!file) return '';
    const fileRef = ref(storage, `${folder}/${Date.now()}_${file.name}`);
    const uploadTaskSnapshot = await uploadBytes(fileRef, file);
    return getDownloadURL(uploadTaskSnapshot.ref);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // 모든 필드가 채워져 있는지 확인
    if (!mainTitle || !typeATitle || !typeBTitle || !typeAThumbnail || !typeBThumbnail) {
      setSnackbarMessage('모든 필드를 채워주세요.');
      setSnackbarType('error');
      setOpenSnackbar(true);
      return; // 필수 필드가 누락되었으므로 여기서 함수를 종료
    }
  
    // 파일 업로드 로직
    const typeAImageUrl = await uploadFileToStorage(typeAThumbnail, 'typeAThumbnails');
    const typeBImageUrl = await uploadFileToStorage(typeBThumbnail, 'typeBThumbnails');
    const uploadTimestamp = new Date();
  
    try {
      await addDoc(collection(firestore, "matchup"), {
        mainTitle,
        typeATitle,
        typeBTitle,
        TypeAThumbnail: typeAImageUrl,
        TypeBThumbnail: typeBImageUrl,
        uploadDate: uploadTimestamp
      });
  
      setSnackbarMessage('업로드 성공!');
      setSnackbarType('success');
      resetForm();
    } catch (error) {
      console.error("Error uploading data: ", error);
      setSnackbarMessage('업로드 실패.');
      setSnackbarType('error');
    }
    setOpenSnackbar(true);
  };

  const resetForm = () => {
    setMainTitle('');
    setTypeATitle('');
    setTypeBTitle('');
    setTypeAThumbnail(null);
    setTypeBThumbnail(null);
    setTypeAPreview('');
    setTypeBPreview('');
  };

  const nextStep = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const previousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} sx={{ mt:10 }}>
              <Typography variant='h5' textAlign="center">
                Welcome to 180Duck
              </Typography>
              <Typography variant='h6' textAlign="center">
                Create a Matchup ever you want!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                hiddenLabel
                variant="outlined"
                placeholder="Main Title"
                value={mainTitle}
                onChange={(e) => setMainTitle(e.target.value)}
                inputProps={{
                  style: { textAlign: 'center' }
                }}
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sx={{ mt:5 }}>
              <Typography variant='h5' textAlign="center">
                Type A
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt:3 }}>
              <Card>
              <Button component="label" fullWidth>
                {typeAPreview ? (
                  <CardMedia
                    component="img"
                    height="200"
                    image={typeAPreview}
                    alt="Uploaded image"
                  />
                ) : (
                  <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <PhotoCamera fontSize="large" />
                  </div>
                )}
                <input type="file" hidden accept="image/*" onChange={(e) => handleFileChange('typeA', e)} />
              </Button>
                <CardContent>
                  <TextField
                    fullWidth
                    hiddenLabel
                    variant="outlined"
                    value={typeATitle}
                    onChange={(e) => setTypeATitle(e.target.value)}
                    placeholder="Main Title"
                    inputProps={{
                      style: { textAlign: 'center' }
                    }}
                  />
                </CardContent>
              </Card>
          </Grid>
        </Grid>
        );
      case 3:
        return (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sx={{ mt:5 }}>
              <Typography variant='h5' textAlign="center">
                Type B
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt:3 }}>
            <Card>
            <Button component="label" fullWidth>
              {typeBPreview ? (
                <CardMedia
                  component="img"
                  height="200"
                  image={typeBPreview}
                  alt="Uploaded image"
                />
              ) : (
                <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <PhotoCamera fontSize="large" />
                </div>
              )}
              <input type="file" hidden accept="image/*" onChange={(e) => handleFileChange('typeB', e)} />
            </Button>
              <CardContent>
                <TextField
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  value={typeBTitle}
                  onChange={(e) => setTypeBTitle(e.target.value)}
                  placeholder="Main Title"
                  inputProps={{
                    style: { textAlign: 'center' }
                  }}
                />
              </CardContent>
            </Card>
            </Grid>
          </Grid>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit}>
        {renderStepContent()}
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sx={{ mt:2 }}>
            {step > 1 && <Button fullWidth onClick={previousStep} variant="outlined">Previous</Button>}
          </Grid>
          <Grid item xs={12}>
            {step < 3 && <Button fullWidth onClick={nextStep} variant="outlined" color="primary">Continue</Button>}
          </Grid>
          <Grid item xs={12}>
            {step === 3 && <Button fullWidth type="submit" variant="outlined" color="primary">업로드</Button>}
          </Grid>
        </Grid>
      </form>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarType} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}