// MainContainer.js

import React, { useEffect, useState } from 'react';
import { Container, Grid, Button, ButtonGroup, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { MainBlock, MobileMainBlock } from '../components/MainBlocks';
import { firestore } from '../configs/firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // 추가

export default function MainContainer() {
   const [data, setData] = useState([]);
   const [filter, setFilter] = useState('latest');
   const [isLoading, setIsLoading] = useState(false);
   const [error, setError] = useState('');

   const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

   const navigate = useNavigate(); // 네비게이션 훅 사용

   useEffect(() => {
      fetchData();
   }, [filter]);

   const fetchData = async () => {
      setIsLoading(true);
      setError('');
      try {
         const querySnapshot = await getDocs(collection(firestore, 'matchup'));
         let fetchedData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
         }));

         if (filter === 'popular') {
            fetchedData.sort((a, b) => {
               const votesA = (a.typeAVotes || 0) + (a.typeBVotes || 0);
               const votesB = (b.typeAVotes || 0) + (b.typeBVotes || 0);
               return votesB - votesA;
            });
         }

         setData(fetchedData);
      } catch (error) {
         console.error('Error fetching data: ', error);
         setError('데이터 로딩 중 오류가 발생했습니다.');
      }
      setIsLoading(false);
   };

   const handleFilterChange = (newFilter) => {
      setFilter(newFilter);
   };

   return (
      <Container maxWidth="lg" sx={{ pb: '80px' }}>
         <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
         >
            <Grid item xs={12} sx={{ m: '40px 0 0 0' }}>
               <Typography variant="h4">BALANCE GAME</Typography>
            </Grid>
            <Grid item xs={12}>
               <ButtonGroup size="large">
                  <Button
                     onClick={() => handleFilterChange('popular')}
                     variant="text"
                     sx={{
                        fontWeight: 'bold',
                        color: filter === 'popular' ? '#f95700' : '#000',
                     }}
                  >
                     🔥 HoT
                  </Button>
                  <Button
                     onClick={() => handleFilterChange('latest')}
                     variant="text"
                     sx={{
                        fontWeight: 'bold',
                        color: filter === 'latest' ? '#f95700' : '#000',
                     }}
                  >
                     Latest
                  </Button>
               </ButtonGroup>
            </Grid>

            {isLoading ? (
               <Grid item xs={12}>
                  <CircularProgress />
               </Grid>
            ) : error ? (
               <Typography>{error}</Typography>
            ) : (
               data.map((match) => (
                  // <Grid item xs={12} sm={6} md={4} key={match.id}>
                  //    {isMobile ? (
                  //       <MobileMainBlock
                  //          match={match}
                  //          onClick={() => navigate(`/match/${match.id}`)}
                  //       />
                  //    ) : (
                  //       <MainBlock match={match} />
                  //    )}
                  // </Grid>
                  <Grid item xs={12} sm={6} md={4} key={match.id}>
                      <MainBlock match={match} />
                  </Grid>
               ))
            )}
         </Grid>
      </Container>
   );
}