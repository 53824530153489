import React from "react";

import MainContainer from "../containers/MainContainer"

export default function MainViews() {
  return (
    <React.Fragment>
      <MainContainer sx={{mb:20}}/>
    </React.Fragment>
  );
}
