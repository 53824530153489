import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';

import { RecoilRoot } from "recoil";
import createRoutes from "./routes";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "./configs/customTheme";
import './index.css';

import reportWebVitals from './reportWebVitals';

// GA4 측정 ID
const ga4Id = 'G-JMV89XBFR6';

// GA4 초기화
ReactGA.initialize(ga4Id);

const routes = createRoutes();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {routes}
      </ThemeProvider>
    </RecoilRoot>   
  </React.StrictMode>
);

// 페이지 뷰 추적 (예: 루트 경로로 접속 시)
ReactGA.send({ hitType: "pageview", page: "/" });

reportWebVitals();