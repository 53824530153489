//MainBlocks.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, Typography, Box, Card, CardMedia, CardActionArea, Snackbar, Alert } from '@mui/material';

const AnimationCard = {
  width: "100%",
  borderRadius: "20px",
  transition: "all 0.5s ease",
  "&:hover": {
    transform: "translateY(-3px)",
  },
};

function MobileMainBlock({ match }) {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleClick = () => {
    navigate(`/match/${match.id}`);
  };

  const handleShare = () => {
    const url = `http://180duck.com/match/${match.id}`;
    navigator.clipboard.writeText(url).then(() => {
      setSnackbarMessage('링크가 클립보드에 복사되었습니다!');
      setOpenSnackbar(true);
    }).catch(err => {
      console.error('링크 복사 실패: ', err);
      setSnackbarMessage('링크 복사 실패');
      setOpenSnackbar(true);
    });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box sx={{ background:'#f3f3f3', p:'20px 0' }}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item container xs={5} alignItems="center" spacing={2} onClick={handleClick}>
          <Grid item xs={12} align="center">
            <Box sx={{ borderRadius: '50%', overflow: 'hidden', width: '110px', display: 'flex', justifyContent: 'center' }}>
              <img src={match.TypeAThumbnail} style={{ height: '110px' }} alt={match.typeATitle} />
            </Box>
          </Grid>
          <Grid item xs={12} align="center">
            <Typography variant='h6'>{match.typeATitle}</Typography>
            <Typography variant='subtitle2'>{match.typeAVotes} 투표</Typography>
          </Grid>
        </Grid>

        <Grid item xs={2} textAlign='center'>
          <Typography variant='h6'>VS</Typography>
        </Grid>

        <Grid item container xs={5} alignItems="center" spacing={2} onClick={handleClick}>
          <Grid item xs={12} align="center">
            <Box sx={{ borderRadius: '50%', overflow: 'hidden', width: '110px', display: 'flex', justifyContent: 'center' }}>
              <img src={match.TypeBThumbnail} style={{ height: '110px' }} alt={match.typeBTitle} />
            </Box>
          </Grid>
          <Grid item xs={12} align="center">
            <Typography variant='h6'>{match.typeBTitle}</Typography>
            <Typography variant='subtitle2'>{match.typeBVotes} 투표</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} align="left">
          <Button variant="text" size="large" sx={{ color:'#f95700', m:'20px 0 0 20px' }} onClick={handleShare}>
            Share
          </Button>
        </Grid>
      </Grid>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

function MainBlock({ match }) {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleClick = () => {
    navigate(`/match/${match.id}`);
  };

  const handleShare = () => {
    const url = `http://180duck.com/match/${match.id}`;
    navigator.clipboard.writeText(url).then(() => {
      setSnackbarMessage('링크가 클립보드에 복사되었습니다!');
      setOpenSnackbar(true);
    }).catch(err => {
      console.error('링크 복사 실패: ', err);
      setSnackbarMessage('링크 복사 실패');
      setOpenSnackbar(true);
    });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
<Box>
  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
    <Grid item xs={12}>
      <Card sx={AnimationCard}>
        <CardActionArea>
          <Grid container onClick={handleClick} justifyContent="center">
            <Grid item xs={12} sx={{ mt:2, mb:2, width: '100%', textAlign: 'center' }}>
                <Typography
                  variant='body1'
                  textAlign='center'
                  display='block' // 블록 레벨 요소로 설정
                  sx={{
                    margin: 'auto', // 중앙 정렬을 위한 자동 마진
                    width: 200, // 너비 제한
                    overflow: 'hidden', 
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2, // 2줄 표시
                    textOverflow: 'ellipsis', 
                    whiteSpace: 'normal', 
                    height: '2.4em', 
                    lineHeight: '1.2em',
                  }}
                >
                    {match.mainTitle}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <CardMedia component="img" height="200" image={match.TypeAThumbnail} alt={match.typeATitle} />
                </Grid>
                <Grid item xs={6}>
                  <CardMedia component="img" height="200" image={match.TypeBThumbnail} alt={match.typeBTitle} />
                </Grid>
                <Grid item xs={6}>
                  <Typography textAlign='center' variant='body1'>
                    {match.typeATitle}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography textAlign='center' variant='body1'>
                    {match.typeBTitle}
                  </Typography>
                </Grid>
              </Grid>
            </CardActionArea>
              <Button variant="text" size="medium" sx={{ color:'#f95700' }} onClick={handleShare}>
                Share
              </Button>
          </Card>
        </Grid>
      </Grid>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export { MainBlock, MobileMainBlock };