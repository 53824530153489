//index.js

import React from "react";
import { Link } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Grid from '@mui/material/Grid';
import MainView from "../views/MainView";
import DatauploadView from "../views/DatauploadView";
import MatchDetailView from "../views/MatchDetailView";

import { ResponsiveAppBar, ResponsiveFooter, TopAppBarMd } from '../components/Navigation';
import TermsView from '../views/TermsView';
import PrivacypolicyView from '../views/PrivacypolicyView';

const createRoutes = (props) => {
  return (
    <BrowserRouter>
      <Grid container 
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ m:1 }}
      >
        <Grid item component={Link} to="/">
          <img src="/logo.png" alt="Logo" style={{ maxHeight: '50px' }}/>
        </Grid>
      </Grid>
      <TopAppBarMd/>
        <Routes>
          <Route exact path="/" element={<MainView/>} />
          <Route exact path="/main" element={<MainView/>} />
          <Route exact path="/dataupload" element={<DatauploadView/>} />
          <Route exact path="/match/:matchId" element={<MatchDetailView/>} />
          <Route exact path="terms" element={<TermsView/>} />
          <Route exact path="privacypolicy" element={<PrivacypolicyView/>} />
        </Routes>
      <ResponsiveFooter />
    </BrowserRouter>
  );
};

export default createRoutes;