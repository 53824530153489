//MatchDetailBlock.js

import React, { useEffect, useState } from 'react';
import { Container, useMediaQuery,CardActionArea, Box, Card, CardContent, CardMedia, Typography, 
  Grid, TextField, Button, List, ListItem, ListItemText,Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ko } from 'date-fns/locale'; 
import { firestore } from '../configs/firebaseConfig';
import { formatDistanceToNow } from 'date-fns';
import { doc, getDoc, collection, addDoc, query, onSnapshot, updateDoc } from 'firebase/firestore';

function MatchDetail() {
  const { matchId } = useParams();
  const [matchData, setMatchData] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [nickname, setNickname] = useState('');
  const [comments, setComments] = useState([]);

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const navigate = useNavigate(); // 네비게이션 훅 사용

  useEffect(() => {
    const fetchMatchData = async () => {
      const docRef = doc(firestore, "matchup", matchId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setMatchData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchMatchData();

    const q = query(collection(firestore, `matchup/${matchId}/comments`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const commentsArray = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate()
      }));
      setComments(commentsArray);
    });

    return () => unsubscribe();
  }, [matchId]);

    // 투표 기능 추가
    const handleVote = async (type) => {
      const matchRef = doc(firestore, "matchup", matchId);
  
      try {
        const docSnap = await getDoc(matchRef);
        if (docSnap.exists()) {
          let newVotes = (docSnap.data()[type] || 0) + 1; // 기존 투표 수에 1 추가
          await updateDoc(matchRef, {
            [type]: newVotes,
          });
  
          setMatchData({
            ...matchData,
            [type]: newVotes,
          });
        }
      } catch (error) {
        console.error("Error updating votes: ", error);
      }
    };
  
  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleNicknameChange = (event) => {
    setNickname(event.target.value);
  };

  const submitComment = async () => {
    if (newComment.trim() === '' || nickname.trim() === '') return;

    const commentData = {
      nickname: nickname,
      text: newComment,
      createdAt: new Date(),
    };

    try {
      await addDoc(collection(firestore, `matchup/${matchId}/comments`), commentData);
      setNewComment('');
      setNickname('');
    } catch (error) {
      console.error("Error adding comment: ", error);
    }
  };

  // const formatCommentDate = (date) => {
  //   const formattedDate = formatDistanceToNow(date, { addSuffix: true });
  //   if (formattedDate === 'less than a minute ago') {
  //     return 'just now';
  //   }
  //   return formattedDate;
  // };
  

  const formatCommentDate = (date) => {
    const formattedDate = formatDistanceToNow(date, { addSuffix: true, locale: ko });
    if (formattedDate === 'less than a minute ago') {
      return '방금 전';
    }
    return formattedDate;
  };

  if (!matchData) {
    return <div>Loading...</div>;
  }

  // function MobileMainBlock() {
  //   return (
  //     <Box sx={{ background:'#f3f3f3',borderRadius:'20px', p:'20px 0', }}>
  //       <Grid container direction="row" justifyContent="center" alignItems="center">
  //         <Grid item container xs={5} alignItems="center" spacing={2} onClick={() => handleVote('typeAVotes')}>
  //           <Grid item xs={12} align="center">
  //             <Box sx={{ borderRadius: '50%', overflow: 'hidden', width: '110px', display: 'flex', justifyContent: 'center' }}>
  //               <img src={matchData.TypeAThumbnail} style={{ height: '110px' }} alt={matchData.typeATitle} />
  //             </Box>
  //           </Grid>
  //           <Grid item xs={12} align="center">
  //             <Typography variant='h6'>{matchData.typeATitle}</Typography>
  //             <Typography variant='subtitle2'>{matchData.typeAVotes || 0} 표</Typography>
  //           </Grid>
  //         </Grid>
  
  //         <Grid item xs={2} textAlign='center'>
  //           <Typography variant='h6'>VS</Typography>
  //         </Grid>
  
  //         <Grid item container xs={5} alignItems="center" spacing={2} onClick={() => handleVote('typeBVotes')}>
  //           <Grid item xs={12} align="center">
  //             <Box sx={{ borderRadius: '50%', overflow: 'hidden', width: '110px', display: 'flex', justifyContent: 'center' }}>
  //               <img src={matchData.TypeBThumbnail} style={{ height: '110px' }} alt={matchData.typeBTitle} />
  //             </Box>
  //           </Grid>
  //           <Grid item xs={12} align="center">
  //             <Typography variant='h6'>{matchData.typeBTitle}</Typography>
  //             <Typography variant='subtitle2'>{matchData.typeBVotes || 0} 표</Typography>
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </Box>
  //   );
  // }

  function PcMainBlock() {
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center" textAlign="center">
        <Grid item xs={5}>
          <Card onClick={() => handleVote('typeAVotes')}>
            <CardActionArea>
            <CardMedia component="img" height="200" image={matchData.TypeAThumbnail} alt={matchData.typeATitle} />
            <CardContent>
              <Typography gutterBottom
                sx={{ typography: { xs: 'subtitle2', md: 'h5' } }}
              >
                {matchData.typeATitle}
              </Typography>
            </CardContent>
            </CardActionArea>
          </Card>
          <br/>
          <Typography variant="h5" textAlign="center">
            {matchData.typeAVotes || 0} Votes
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography textAlign="center"
            sx={{ fontWeight: 'bold', typography: { xs: 'h5', md: 'h2' }, }}
          >
            VS
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Card onClick={() => handleVote('typeBVotes')}>
          <CardActionArea>
            <CardMedia component="img" height="200" image={matchData.TypeBThumbnail} alt={matchData.typeBTitle} />
            <CardContent>
              <Typography gutterBottom variant="h5"
                sx={{ typography: { xs: 'subtitle2', md: 'h5' } }}
              >
                {matchData.typeBTitle}
              </Typography>
            </CardContent>
            </CardActionArea>
          </Card>
          <br/>
          <Typography variant="h5" textAlign="center">
            {matchData.typeBVotes || 0} Votes
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" alignItems="center" textAlign="center" sx={{ m:'20px 0 80px 0' }}>
      <Grid item xs={12} key={matchData.id}>
        {/* {isMobile ? <MobileMainBlock /> : <PcMainBlock />} */}
        <PcMainBlock />
      </Grid>
      
        <Grid container spacing={2} sx={{ m:'20px 0 0 0' }}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="NickName . . ."
              value={nickname}
              onChange={handleNicknameChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              label="Comment . . ."
              value={newComment}
              onChange={handleCommentChange}
              variant="outlined"
              multiline
              rows={1}
            />
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={4} align="right">
            <Button variant="contained" color="primary" sx={{ color:'#FFFFFF', fontWeight:'bold' }} onClick={submitComment} fullWidth disableElevation>
              Comment
            </Button>
          </Grid>
        </Grid>

        <List sx={{ width: '100%' }}>
          {comments.map((comment, index) => (
            <ListItem key={index} alignItems="flex-start">
              <ListItemText
                primary={`${comment.nickname}`}
                secondary={
                  <>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {comment.text}
                    </Typography>
                    <br />
                    {formatCommentDate(comment.createdAt)}
                    <Divider />
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
    </Grid>
  );
}

export default MatchDetail;
